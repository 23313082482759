<template>
  <div>
    <topbar
      :titulo="`Curso ${curso.descricao}`"
      :subtitulo="$t('ACADEMICO.CURSO_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    >
      <template #header>
        <div class="d-flex justify-content-between ml-4">
          <b-button @click="irPara()" variant="secondary" class="mr-2">{{
            $t('GERAL.VOLTAR')
          }}</b-button>
        </div>
      </template>
    </topbar>
    <div>
      <p class="m-0"><b>Carga Horária:</b> {{ curso.cargaHoraria }}</p>
      <p class="m-0">
        <b>Tipo de Contrato:</b> {{ curso.tipoContratoDescricao }}
      </p>
      <p class="m-0"><b>Número de turmas neste curso:</b> {{ numeroTurmas }}</p>
      <p class="mt-3"><b>Ementa:</b><br />{{ curso.ementa }}</p>
    </div>
  </div>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  props: {
    curso: { type: Object, default: Object },
    numeroTurmas: { type: Number, default: Number },
  },
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.ESTRUTURA_CEJ') },
        { titulo: this.$t('ACADEMICO.CURSOS') },
        { titulo: `Curso ${this.curso.descricao}` },
      ],
    };
  },
  methods: {
    irPara() {
      this.$router.push({ name: 'academico-estrutura-cej-cursos' });
    },
  },
};
</script>
