<template>
  <!--
        Descrição da view:
        - Apresenta todas as turmas do curso pelo ID vindo da rota :)
    -->
  <div>
    <div class="row">
      <div class="col-12 mb-5">
        <dados-curso :curso="dados.curso" :numeroTurmas="dados.turmas.length" />
      </div>
      <div class="col-12">
        <b-tabs content-class="mt-3">
          <b-tab title="Turmas" active>
            <turmas
              :turmas="dados.turmas"
              @editar="openModal"
              @excluir="excluir"
            />
          </b-tab>
          <!-- <b-tab title="Alguma coisa legal aqui :)">
            Não sei bro, às vezes as coisas são melhores na nossa cabeça :(((
          </b-tab> -->
        </b-tabs>
      </div>
    </div>

    <modal-turma
      :form="modais.turmaDados"
      :exibir="modais.turma"
      @salvar="editarTurma"
      @fechar="closeModal('turma')"
      @atualizar="getTurma"
    />
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import CursoService from '@/common/services/academico/curso.service';
import TurmasService from '@/common/services/academico/turma.service.js';

// Components:
import Turmas from '@/views/academico/turmas/components/TabelaTurmas.vue';
import DadosCurso from './components/DadosCurso';
import ModalTurma from '@/views/academico/turmas/components/ModalTurma.vue';

export default {
  components: {
    Turmas,
    DadosCurso,
    ModalTurma,
  },
  mounted() {
    this.getCurso();
    this.getTurma();
  },
  data() {
    return {
      id: this.$route.params.id,
      dados: {
        curso: {},
        turmas: [],
      },
      modais: {
        turma: false,
        turmaDados: {},
      },
    };
  },
  methods: {
    // FUNÇÕES MODAL:
    getCurso() {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorId(this.id)
        .then(({ data }) => {
          this.dados.curso = data;
        })
        .catch(() => {
          this.dados.curso = 'Não encontrado';
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTurma() {
      this.$store.dispatch(START_LOADING);
      TurmasService.buscarPorCursoId(this.id)
        .then(({ data }) => {
          this.dados.turmas = data;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = {});
      this.modais[key] = true;
    },
    closeModal(modal) {
      return (this.modais[modal] = false);
    },
    // FUNÇÕES CRUD
    editarTurma() {
      this.$store.dispatch(START_LOADING);
      TurmasService.editar(this.modais.turmaDados)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Turma editada!');
          this.filtrar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('turma');
          this.$store.dispatch(STOP_LOADING);
        });
    },

    excluir(id) {
      mensagem
        .confirmacao(null, this.$t('Deseja excluir turma ?'))
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            TurmasService.excluir(id)
              .then(() => {
                this.getTurma();
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('Turma excluída com sucesso')
                );
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },
  },
};
</script>
